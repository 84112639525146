<template>
  <div class="container">
    <h1 v-html="$t('application_form_revisions_title')"></h1>
    <!-- needs rework -->
  </div>
</template>
<script lang="ts">
import { showError } from '@/services/axios'
import { DTSHeader } from '@/services/BackendService'

const applicationFormRowMinimal = {
  bio: 0
}

import { apiURL } from '../../../main'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'processingFormRevisions',
  data() {
    return {
      size: 10,
      processingFormRevisionfields: <DTSHeader[]>[
        { key: 'entity.versionNo', sortable: true },
        { key: 'entity.formStatus.nameDe', label: 'Name', sortable: true }
      ],
      processingFormRowRevisionfields: <DTSHeader[]>[
        { key: 'entity.id', sortable: true },
        { key: 'entity.quantity', sortable: true },
        { key: 'entity.formStatus.nameDe', sortable: true },
        { key: 'entity.fat', sortable: true },
        { key: 'entity.loafCount', sortable: true }
      ],
      processingFormRevisions: [],
      processingFormRowRevisions: []
    }
  },
  methods: {
    goBack() {
      this.$router.push({
        name: 'processingForms_read'
      })
    },
    async getProcessingFormRevisions() {
      try {
        const result = await this.axios.get(
          apiURL + '/processingForms/' + this.$route.params.id + '/revisions',
          {
            id: this.$route.params.id
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          return result.data.content
        }
      } catch (e) {
        showError(e)
      }
    },
    async getProcessingFormRowRevisions() {
      try {
        const result = await this.axios.get(
          apiURL + '/processingFormRows/revisions',
          {
            params: {
              processingFormId: this.$route.params.id
            }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          return result.data.content
        }
      } catch (e) {
        showError(e)
      }
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      this.processingFormRevisions = await this.getProcessingFormRevisions()
      this.processingFormRowRevisions = await this.getProcessingFormRowRevisions()
    })
  }
})
</script>
<style lang="scss" scoped>
option:disabled {
  color: lightgray !important;
}

ul > li {
  text-align: left;
}

.fooBar {
  display: flex;
  flex-direction: row;
}
</style>
